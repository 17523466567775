import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'zeroDate',
})
export class ZeroDatePipe implements PipeTransform {
  transform(date: string): string {
    const timeFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    const dateObj = new Date(date).toLocaleString('en-US', timeFormat);
    return dateObj;

    // const dateObj: moment.Moment = moment(date);

    // return dateObj.format('MMMM DD, YYYY HH:mm:ss');

  }
}
