import { Component } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'San Antonio ADMIN';
  language: string = 'es';
  loaded: boolean = false;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.handleTitle(item.url);
      }
    });
  }

  handleTitle(url: string) {
    const urlParts = url.split('/');
    const title = urlParts[2];

    if (!title) {
      this.titleService.setTitle(`${environment.title}`);
      return;
    }

    switch (title) {
      case 'agencies':
        this.titleService.setTitle(`Agencies - ${environment.title}`);
        break;

      case 'submissions':
        this.titleService.setTitle(`Entries - ${environment.title}`);
        break;

      case 'permissions':
        this.titleService.setTitle(`Permissions - ${environment.title}`);
        break;

      case 'roles':
        this.titleService.setTitle(`Roles - ${environment.title}`);
        break;

      case 'questionnaires':
        this.titleService.setTitle(`Questionnaires - ${environment.title}`);
        break;

      case 'users':
        this.titleService.setTitle(`Users - ${environment.title}`);
        break;

      case 'zones':
        this.titleService.setTitle(`Zones - ${environment.title}`);
        break;

      default:
        this.titleService.setTitle(`${environment.title}`);

        break;
    }
  }
}
