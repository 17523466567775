<footer class="footer">
  <img
    src="assets/images/logo-san-antonio.png"
    alt="San Antonio"
    class="footer-logo"
  />

  <div class="row text-center m-0">
    <div class="col-12 mt-4 mb-3">
      Copyright &copy; 2023 City of San Antonio
    </div>

    <div class="col-12 text-center">
      <div class="footer-text">
        Privacy Policy and Disclaimer <div class="logo-divider"></div> sanantonio.gov
      </div>
    </div>
  </div>
</footer>
