<div class="app">
  <app-header></app-header>

  <main [ngClass]="{ loading: loadingService.getLoading() }">
    <router-outlet></router-outlet>
  </main>

  <div class="spinner-overlay" *ngIf="loadingService.getLoading()">
    <div class="spinner"></div>

    <div class="spinner-label" *ngIf="loadingService.label">
      <h5 class="font-italic">{{ loadingService.label }}</h5>
    </div>
  </div>

  <app-footer></app-footer>
</div>
