<nav class="navbar navbar-expand-lg header">
  <div class="main-bar">
    <div class="container">
      <img src="assets/images/bitmap.png" height="20" alt="San Antonio" />

      <small class="ms-2"> Oficial website of the City of San Antonio </small>
    </div>
  </div>

  <div class="container-xxl second-bar px-3 px-lg-4">
    <a class="navbar-brand" routerLink="/">
      <div class="header-logos">
        <img src="assets/images/logo.png" alt="San Antonio" />

        <div class="logo-divider mx-2 mx-sm-3"></div>

        <div class="d-flex flex-column">
          <div class="my-auto">
            DIGITAL <br />
            PRESENCE <br />
            PROGRAM
          </div>
        </div>
      </div>
    </a>

    <button
      class="navbar-toggler me-sm-2"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      [ngbCollapse]="isMenuCollapsed"
      class="collapse navbar-collapse pt-3 pt-lg-0"
    >
      <ul class="navbar-nav ms-auto" *ngIf="!isLoggedIn">
        <li class="nav-item mb-2 mb-sm-0 me-lg-3">
          <a
            class="btn btn-outline-dark btn-3d"
            routerLink="/auth/login"
            (click)="closeMenu()"
          >
            Login

            <i class="fa-solid fa-arrow-right-long ms-auto"></i>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto" *ngIf="isLoggedIn">
        <li
          class="nav-item mb-3 mb-lg-0 me-lg-3"
          *ngFor="let s of sections; index as ind"
        >
          <a
            class="btn btn-outline-dark btn-3d"
            routerLinkActive="nav-active"
            [routerLink]="s.route"
            (click)="closeMenu()"
            *ngIf="s.children.length === 0"
          >
            {{ s.name }}

            <i class="fa-solid fa-arrow-right ms-2"></i>
          </a>

          <div class="w-100" ngbDropdown *ngIf="s.children.length > 0">
            <button
              id="accountButton"
              class="btn btn-outline-dark btn-3d"
              tabindex="0"
              ngbDropdownToggle
              role="button"
            >
              {{ s.name }}
            </button>

            <div ngbDropdownMenu aria-labelledby="accountButton" class="btn-3d">
              <a
                ngbDropdownItem
                [routerLink]="child.route"
                (click)="closeMenu()"
                *ngFor="let child of s.children"
              >
                {{ child.name }}

                <i class="fa-solid fa-arrow-right ms-auto"></i>
              </a>
            </div>
          </div>
        </li>

        <li class="nav-item mb-2 mb-sm-0 me-lg-3" ngbDropdown>
          <button
            id="accountButton"
            class="btn btn-outline-dark btn-3d"
            tabindex="0"
            ngbDropdownToggle
            role="button"
          >
            My Account
          </button>

          <div ngbDropdownMenu aria-labelledby="accountButton" class="btn-3d">
            <a
              ngbDropdownItem
              routerLink="/admin/profile/me"
              aria-label="Settings"
              tabindex="0"
            >
              Settings
            </a>
            
            <button ngbDropdownItem (click)="logOut()">
              Log out

              <i class="fa-solid fa-arrow-right-from-bracket ms-auto"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
