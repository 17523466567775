<circle-progress
  [percent]="value"
  [space]="-18"
  unitsFontSize="60"
  [unitsColor]="'#0F0F0F'"
  [titleColor]="'#0F0F0F'"
  titleFontSize="60"
  [innerStrokeWidth]="18"
  [outerStrokeWidth]="18"
  [outerStrokeColor]="color"
  [innerStrokeColor]="'#e7e8ea'"
  [title]="'' + value"
  [animateTitle]="false"
  [animationDuration]="700"
  [showSubtitle]="false"
  [showBackground]="false"
  [showZeroOuterStroke]="false"
  [lazy]="false"
  [responsive]="true"
  [renderOnClick]="false"
></circle-progress>