import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private profileObservable = new BehaviorSubject<boolean>(false);
  profileChanged = this.profileObservable.asObservable();

  constructor(private storage: LocalStorageService) {}

  get logged(): boolean {
    return this.token ? true : false;
  }

  can(group: string, perm: string): boolean {
    let p = this.storage.get<string[]>('permissions') ?? [];
    // console.log(group + '.' + perm);
    return p.includes(group + '.' + perm);
  }

  set(data: any) {
    this.storage.set('token', data.token);
    this.profileObservable.next(true);
  }

  permissions(data: any) {
    this.storage.set('permissions', data);
  }

  // TOKEN KEY
  get token(): string {
    return this.storage.get<string>('token') ?? '';
  }

  set token(token: string) {
    this.storage.set('token', token);
    this.profileObservable.next(true);
  }

  clearSession() {
    this.token = '';
    this.storage.cleanStorage();
    this.profileObservable.next(false);
  }
}
