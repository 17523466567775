import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.scss'],
})
export class MainModalComponent {
  @Input() title: string = '';
  @Input() hasFooter: boolean = true;
  @Input() loading: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter();

  onClose() {
    this.close.emit();
  }
}
