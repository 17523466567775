<div class="modal-content">
  <div class="modal-header px-lg-4" [ngClass]="{ 'placeholder-glow': loading }">
    <h4
      class="modal-title me-auto mb-0 placeholder"
      [ngClass]="{ placeholder: loading }"
    >
      {{ title }}
    </h4>

    <i
      class="fa-solid fa-xmark fa-xl clickable"
      [ngClass]="{ placeholder: loading }"
      (click)="onClose()"
    ></i>
  </div>

  <div class="modal-body">
    <ng-content select="[content]"></ng-content>
  </div>

  <div class="modal-footer" *ngIf="hasFooter">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
