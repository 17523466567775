import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {
  NgbAlertModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { ZeroDatePipe } from './pipes/zero-date.pipe';
import { MainModalComponent } from './components/main-modal/main-modal.component';
import { MaterialModule } from '../modules/material/material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationPipe } from './pipes/location.pipe';

@NgModule({
  declarations: [
    // COMPONENTES
    CircleProgressComponent,
    MainModalComponent,

    // PIPES
    ZeroDatePipe,
    LocationPipe,
  ],
  imports: [
    CommonModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbModule,
    NgbCollapseModule,
    NgSelectModule,
    NgbDropdownModule,
    MaterialModule,
    NgCircleProgressModule.forRoot(),

    GoogleMapsModule,
  ],
  exports: [
    // COMPONENTS
    CircleProgressComponent,
    MainModalComponent,

    // PIPE
    ZeroDatePipe,
    LocationPipe,

    // MODULES
    NgCircleProgressModule,
    MaterialModule,
    NgbCollapseModule,
    NgbAlertModule,
    NgbModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgSelectModule,

    GoogleMapsModule,
  ],
})
export class CoreModule {}
