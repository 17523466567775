import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

class Section {
  name: string = '';
  permission: string = '';
  route: string = '';
  children: Section[] = [];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isMenuCollapsed: boolean = true;
  isLoggedIn: boolean = false;
  sections: Section[] = [];

  constructor(
    private user: UserService,
    private router: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.user.profileChanged.subscribe((loggedIn) => {
      this.isLoggedIn = this.user.logged;

      if (this.isLoggedIn) {
        this.permissions();

        this.api.call('admin/catalogs/sections', Section).subscribe({
          next: (response) => {
            if (response.status == 200) {
              this.sections = Array.isArray(response.data)
                ? response.data
                : [response.data];
            } else {
              alert(response.message.join('\n'));
            }
          },
          error: (error) => {
            console.error(error);
            alert(error);
          },
        });
      }
    });
  }

  permissions() {
    this.api.call('admin/catalogs/access').subscribe({
      next: (response: any) => {
        if (response.status === 200) this.user.permissions(response.data);
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  closeMenu() {
    this.isMenuCollapsed = true;
  }

  logOut() {
    this.user.clearSession();
    this.isLoggedIn = false;
    this.router.navigate(['/']);
  }
}
