import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'location',
})
export class LocationPipe implements PipeTransform {
  transform(value: any): boolean {
    return !!value.location;
  }
}
