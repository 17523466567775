<div class="page-container">
  <div class="container">
    <div class="row">
      <div class="col">
        <form (ngSubmit)="submit()">
          <!-- Email input -->

          <mat-form-field class="d-block">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              [(ngModel)]="params.email"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>

          <mat-form-field class="d-block">
            <mat-label>Password</mat-label>
            <input
              type="password"
              matInput
              [(ngModel)]="params.password"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>

          <div class="text-danger mb-3" *ngIf="error">
            <small [innerHTML]="error"></small>
          </div>

          <!-- Submit button -->
          <div class="w-100 text-center text-lg-end">
            <button
              type="button"
              class="btn btn-primary btn-block mb-4 px-5"
              (click)="submit()"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
